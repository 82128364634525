export const showToast = (message, type = 'success') => {
    const toast = document.createElement('div');
    toast.classList.add('fixed', 'inset-0', 'bg-slate-800', 'bg-opacity-50', 'z-40');

    const toastTypes = {
        success: {
            icon: `
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" aria-hidden="true" class="w-6 h-6 text-gray-800 dark:text-white"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 11c.889-.086 1.416-.543 2.156-1.057a22.323 22.323 0 0 0 3.958-5.084 1.6 1.6 0 0 1 .582-.628 1.549 1.549 0 0 1 1.466-.087c.205.095.388.233.537.406a1.64 1.64 0 0 1 .384 1.279l-1.388 4.114M7 11H4v6.5A1.5 1.5 0 0 0 5.5 19v0A1.5 1.5 0 0 0 7 17.5V11Zm6.5-1h4.915c.286 0 .372.014.626.15.254.135.472.332.637.572a1.874 1.874 0 0 1 .215 1.673l-2.098 6.4C17.538 19.52 17.368 20 16.12 20c-2.303 0-4.79-.943-6.67-1.475"/></svg>
      `,
            bgColor: 'text-slate-800 bg-slate-200 border border-green-500',
            iconBgColor: 'text-green-500 bg-green-100 border border-green-500 dark:bg-green-800 dark:text-green-200',
        },
        error: {
            icon: `
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" aria-hidden="true" class="w-6 h-6 text-gray-800 dark:text-white"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 13c-.889.086-1.416.543-2.156 1.057a22.322 22.322 0 0 0-3.958 5.084 1.6 1.6 0 0 1-.582.628 1.549 1.549 0 0 1-1.466.087 1.587 1.587 0 0 1-.537-.406 1.666 1.666 0 0 1-.384-1.279l1.389-4.114M17 13h3V6.5A1.5 1.5 0 0 0 18.5 5v0A1.5 1.5 0 0 0 17 6.5V13Zm-6.5 1H5.585c-.286 0-.372-.014-.626-.15a1.797 1.797 0 0 1-.637-.572 1.873 1.873 0 0 1-.215-1.673l2.098-6.4C6.462 4.48 6.632 4 7.88 4c2.302 0 4.79.943 6.67 1.475"/></svg>
      `,
            bgColor: 'text-slate-800 bg-slate-600 border border-red-500',
            iconBgColor: 'text-red-500 bg-red-100 border border-red-500 dark:bg-red-800 dark:text-red-200',
        },
    };

    const {icon, bgColor, iconBgColor} = toastTypes[type] || toastTypes.success;

    toast.innerHTML = `
    <div class="fixed top-[10%] right-[50%] transform translate-x-1/2 z-50 flex items-center w-full max-w-xs p-4 mb-4 rounded-lg shadow ${bgColor}" role="alert">
      <div class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 rounded-lg ${iconBgColor}">
        ${icon}
        <span class="sr-only">Icon</span>
      </div>
      <div class="ms-3 text-sm font-normal text-slate-900">${message}</div>
    </div>
  `;

    document.body.appendChild(toast);

    setTimeout(() => {
        toast.remove();
    }, 2500);
};


export const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text?.trim()).then(() => {
        showToast('Copiado para a área de transferência');
    }).catch(err => {
        console.error('Erro ao copiar texto: ', err);
    });
};

export const getIcons = {
    file: () => `
    <svg  aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <path stroke="currentColor" stroke-linejoin="round" stroke-width="2" d="M10 3v4a1 1 0 0 1-1 1H5m14-4v16a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V7.914a1 1 0 0 1 .293-.707l3.914-3.914A1 1 0 0 1 9.914 3H18a1 1 0 0 1 1 1Z"/>
    </svg>
    `,
    text: () => `
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 6.2V5h11v1.2M8 5v14m-3 0h6m2-6.8V11h8v1.2M17 11v8m-1.5 0h3"/>
    </svg>`,
    link: () => `
    <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"/>
    </svg>`,
};

export const isLink = (text) => {
    const urlPattern = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|]|(\b[-A-Z0-9+&@#\/%=~_|!:,.;]*\.(com|org|net|gov|edu|co|info|br)\b))/ig;
    return urlPattern.test(text?.trim());
};

export const linkify = (text) => {
    const normalizedText = text?.replace(/\s+/g, ' ').trim();
    const urlPattern = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|]|(\b[-A-Z0-9+&@#\/%=~_|!:,.;]*\.(com|org|net|gov|edu|co|info|br)\b))/ig;

    return normalizedText.replace(urlPattern, (match) => {
        if (!match.includes(window.location.hostname)) {
            return `<a class="text-blue-500" href="${match}" target="_blank">${match}</a>`;
        }
        return match;
    });
};
